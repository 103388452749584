import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/BlockchainPage/DescriptionBlock';
import TechSolutions from '../components/BlockchainPage/TechSolutions';
import Benefits from '../components/BlockchainPage/Benefits';
import SubmitQuote from '../components/SubmitQuote';
import Differences from '../components/BlockchainPage/Differences';
import Stack from '../components/Stack';

import iot from '../assets/img/iot.png';
import ethereum from '../assets/img/etherum2.png';
import hyperledger from '../assets/img/hyperledger.png';
import ripple from '../assets/img/ripple.png';
import multichain from '../assets/img/multichain.png';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { blockchain } from '../seoConfig';

const stackImages = [
	{
		icon: iot,
		alt: 'iota logo',
	},
	{
		icon: ethereum,
		alt: 'ethereum logo',
	},
	{
		icon: hyperledger,
		alt: 'hyperledger logo',
	},
	{
		icon: ripple,
		alt: 'ripple logo',
	},
	{
		icon: multichain,
		alt: 'multichain logo',
	},
];

const Blockchain = ({ location }) => {
	const img = useHeroPictureTechnologies();
	return (
		<PageLayout
			location={location}
			seoConfig={blockchain}
			altText={'pixelteh team with computers'}
		>
			<SubpageTopCover backgroundImg={img}>
				Blockchain & Cryptocurrency Development
			</SubpageTopCover>
			<DescriptionBlock />
			<TechSolutions />
			<Benefits />
			<SubmitQuote />
			<Differences />
			<Stack
				title="Top Blockchain Platforms That We Use for Prototyping"
				images={stackImages}
				fluid
			/>
		</PageLayout>
	);
};

Blockchain.propTypes = {
	location: PropTypes.object,
};

export default Blockchain;
