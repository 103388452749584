import React from 'react';
import styled from 'styled-components';
import { Accordion } from 'react-sanfona';
import CategoryList from './CategoryList';
import { media } from '../../../globalStyles';

const data = [
	{
		title: 'Platforms',
		items: [
			'Ethereum',
			'Bitcoin',
			'DASH',
			'HyperLedger Fabric',
			'MultiChain',
			'Quorum',
		],
	},
	{
		title: 'Tools',
		items: ['Solidity', 'Truffle', 'Web3.js', 'Serpent', 'Raiden', 'JSON-RPC'],
	},
	{
		title: 'Tokens',
		items: ['ERC 20', 'ERC 223', 'ERC 664', 'ERC 721'],
	},
];
const StyledTechSolutionsList = styled(Accordion)`
	position: relative;
	z-index: 2;
	display: flex;
	justify-content: space-around;
	${media.maxMd`
		flex-direction: column;
	`}
`;

const TechSolutionsList = () => (
	<StyledTechSolutionsList>
		{data.map(item => (
			<CategoryList key={item.title} title={item.title} items={item.items} />
		))}
	</StyledTechSolutionsList>
);

export default TechSolutionsList;
