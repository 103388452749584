import React from 'react';
import styled from 'styled-components';
import StyledTextContainer from '../_common/TextContainer';
import P from '../_common/P';
import H4 from '../_common/H4';
import H5 from '../_common/H5';

import bcImg from '../../assets/img/blockchain-img.png';
import { colors, fonts } from '../../globalStyles';

const StyledImageDescription = styled.section`
	margin-bottom: 100px;
	.blockchain-img {
		display: block;
		margin: 0 auto 90px;
	}
	${H4} {
		text-transform: uppercase;
		text-align: center;
		font-weight: 400;
		font-family: ${fonts.loveloBlack};
		color: ${colors.blackTitle};
		margin-bottom: 10px;
		span {
			color: ${colors.orange};
		}
	}
	${H5} {
		font-family: ${fonts.robotoRegular};
		line-height: 30px;
		color: #7a8085;
		text-align: center;
		font-size: 16px;
		margin-bottom: 70px;
	}
`;

const ImageDescription = () => (
	<StyledImageDescription>
		<img src={bcImg} alt="blockchain icon" />
		<H4 as={'h3'}>
			<span>BLOCKCHAIN</span> TECHNOLOGY
		</H4>
		<H5 as={'h4'}>Advanced Blockchain Technology</H5>
		<StyledTextContainer>
			<P>
				This unique technology consists of a worldwide and expansive database
				that’s accessible, yet safe. It is based on a distributed, peer-to-peer
				system where data is stored on thousands of servers throughout the
				world. Anyone on the network can see everyone else&apos;s entries in
				real-time. So, it&apos;s virtually impossible for someone to gain
				control of or change the network. Data within a blockchain is shared and
				continually reconciled. Our blockchain developers help to eliminate huge
				amounts of record-keeping, which helps our clients save money and
				improve profits. Even now, blockchain technology is predominant in the
				finance and banking sectors. For instance, it’s used by the United
				States Federal Reserve, the NASDAQ, and Citigroup. The well-developed
				systems we create ensure faster transactions, transparency, reduced
				costs, and improved trust for users.
			</P>
		</StyledTextContainer>
	</StyledImageDescription>
);

export default ImageDescription;
