import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import { colors, fonts, media } from '../../../globalStyles';
import data from './data';

const StyledBenefitsListWrapper = styled.div`
	max-width: 1585px;
	margin: 0 auto;
`;

const StyledBenefitItem = styled.div`
	display: flex;
	align-items: center;
	&:nth-child(2n) {
		${media.minSm`
			flex-direction: row-reverse;
		`}
		.icon-block {
			background-position: 0 center;
		}
	}
	.line {
		position: relative;
    width: 20%;
    height: 1px;
    background: #e8e8e8;
    display: flex;
    align-items: center;
    margin: 0 20px;
    &:before {
			display: block;
			content: "";
			width: 8px;
			height: 8px;
			background-color: #fd4326;
			box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
			border-radius: 50%;
		}
	}
	.icon-block {
		color: ${colors.blackTitle};
    font-family: ${fonts.loveloBlack};
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
    width: 30%;
    padding: 100px 0 100px;
    text-align: right;
    position: relative;
    z-index: 1;
    //background: url(${({ icon }) => icon}) no-repeat 0 center;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: 0 center;
    .title-xs {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translate(-50%);
			color: ${colors.whiteGrey};
			font-family: ${fonts.openSans};
			font-size: 162px;
			font-weight: 400;
			z-index: -1;
			white-space: nowrap;
			&.ethereum {
				font-size: 84px
			}
			&.dapp, &.bitcoin {
				font-size: 100px;
			}
			${media.maxXs`
				font-size: 100px;
				&.ethereum {
					font-size: 64px;
				}
				&.dapp, &.bitcoin {
					font-size: 84px;
				}
			`}
    }
	}
	.description {
		text-align: left;
		color: #82888d;
		line-height: 26px;
		width: 50%;
	}
	${media.laptopS`
		.icon-block {
			background-size: 100%;
		}
	`}
	${media.maxSm`
		flex-direction: column;
		text-align: center;
		margin-bottom: 60px;
		.description{
			width: 100%;
		}
		.icon-block {
			display: block;
			width: 100%;
			background: none;
			text-align: center;
			padding: 60px 0;
		}
		.line {
			position: absolute;
			width: 246px;
			margin: 0;
			&:before{
				left: 50%;
				margin-left: -4px;
				position: absolute;
			}
		}
		&:first-child{
			.line{
				display: none;
			}
		}
	`}
`;

const BenefitItem = ({ title, icon, titleXs, children }) => (
	<StyledBenefitItem icon={icon}>
		<div className="icon-block">
			<span className={`visible-sm title-xs ${titleXs.toLowerCase()}`}>
				{titleXs}
			</span>
			{title}
		</div>
		<div className="line" />
		<div
			className="description"
			dangerouslySetInnerHTML={{ __html: children }}
		/>
	</StyledBenefitItem>
);

BenefitItem.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	titleXs: PropTypes.string,
	children: PropTypes.string,
};

const Benefits = () => (
	<ContentSection
		fluid
		title="Let Us Build a Blockchain Platform for You"
		subtitle="The benefits of using blockchain for your business"
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledBenefitsListWrapper>
			{data.map(item => (
				<BenefitItem
					key={item.title}
					title={item.title}
					icon={item.icon}
					titleXs={item.titleXs}
				>
					{item.text}
				</BenefitItem>
			))}
		</StyledBenefitsListWrapper>
	</ContentSection>
);

export default Benefits;
