import ico from '../../../assets/img/ico-icon.png';
import etherum from '../../../assets/img/etherum.png';
import dapp from '../../../assets/img/dapp-icon.png';
import bitcoin from '../../../assets/img/bitcoin-icon-blockchain.png';
const data = [
	{
		title: 'Transparency',
		titleXs: 'ICO',
		icon: ico,
		text:
			'In a world where fraud runs rampant, wouldn’t it be good to have a system that is virtually unalterable? Blockchain is an open-source technology that allows users or developers the opportunity to modify it as they see fit. However, every action is recorded. Altering logged data within a blockchain is incredibly difficult – more so than any other system. Because anyone on the network can see all the logged data, it makes blockchain an extremely secure technology. Change the way you do business by creating a cryptocurrency/blockchain program with <b>PixelTeh</b>.',
	},
	{
		title: 'Reduced Transaction costs',
		titleXs: 'ethereum',
		icon: etherum,
		text:
			"Not too long ago, phone companies (both landline and mobile) charged for minutes used. Now, due to advances in technology, that is unheard of. It’s the same with blockchain. Third parties, such as banks, make money off fees and other charges. Why pay them when you don’t have to? Blockchain allows peer-to-peer and business-to-business transactions without the assistance of a third party. Since there's no middleman involved, there are reduced costs for users and businesses. Soon, transaction fees could be a thing of the past. Imagine how appealing that could be for your customers!",
	},
	{
		title: 'Faster transaction settlements',
		titleXs: 'Dapp',
		icon: dapp,
		text:
			"When it comes to traditional banks and credit unions, as well as stock exchanges, it's common for transactions to take days to settle. This has to do with the software that financial institutions use as well as the rules placed by various governing bodies. In addition, some financial institutions are located in different time zones, which delays processing times. Blockchain technology allows transactions 24 hours a day and 365 days a year – with no delay! Most importantly, it’s a more secure, accessible, and accurate method to bank and trade.",
	},
	{
		title: 'Decentralization',
		titleXs: 'bitcoin',
		icon: bitcoin,
		text:
			'No central hub, yet highly secure. Another central reason blockchain is so appealing is its lack of a main control center. Instead of running a massive data center, such as Google’s (over 2,000,000 square feet of servers), blockchain allows individual transactions to have their own proof of validity and the authorization to enforce those constraints. Since a blockchain is assembled on different servers, it ensures that fraud or theft is extremely rare – if not nearly impossible. If a cybercriminal is able to access it, only a small amount of data, and not the entire network, would be compromised.',
	},
];

export default data;
