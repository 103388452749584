import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AccordionItem } from 'react-sanfona';
import { fonts, media } from '../../../globalStyles';
import arrowIcon from '../../../assets/img/arrow-sprite.png';

import H4 from '../../_common/H4';

const StyledCategoryList = styled(AccordionItem)`
	${H4} {
		color: #94ceff;
		font-family: ${fonts.openSans};
		font-size: 24px;
		font-weight: 600;
		line-height: 120px;
	}
	${media.maxMd`
		${H4} {
			color: #fff;
			font-size: 22px;
			position: relative;
			line-height: 28px;
			padding: 30px 0 30px 20px;
			font-weight: 300;
			.title-text {
				z-index: 2;
				position: relative;
			}
			&:before {
				content: "";
				display: block;
				width: 44px;
				height: 44px;
				background-color: #f73f0e;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 19px;
				z-index: 1;
			}
			&:after {
				content: "";
				width: 23px;
				height: 11px;
				background: url(${arrowIcon}) -10px -10px;
				display: inline-block;
				margin-left: 15px;
			}
		}
		&.react-sanfona-item-expanded{
			${H4} {
				font-weight: 600;
				&:after {
					visibility: hidden;
				}
			}
		}
	`}
	${media.minMd`
		.react-sanfona-item-body {
			max-height: none!important;
		}
	`}
`;

const List = styled.ul`
	margin: 0;
	padding-left: 40px;
`;

const ListItem = styled.li`
	color: #fff;
	font-family: ${fonts.openSans};
	font-size: 17px;
	font-weight: 400;
	display: flex;
	align-items: center;
	list-style: none;
	line-height: 28px;
	&:before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #94ceff;
		display: inline-block;
		margin-right: 8px;
	}
`;

const CategoryList = ({ title, items, ...props }) => (
	<StyledCategoryList
		title={
			<H4>
				<span className="title-text">{title}</span>
			</H4>
		}
		{...props}
	>
		<List>
			{items.map(item => (
				<ListItem key={item}>{item}</ListItem>
			))}
		</List>
	</StyledCategoryList>
);

CategoryList.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array,
};

export default CategoryList;
