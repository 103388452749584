import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import H3 from '../../_common/H3';
import lock from '../../../assets/img/lock-img.png';
import { fonts, media } from '../../../globalStyles';

const publicData = [
	'No central bank that controls transactions',
	'To date, no cyber-attacker has ever hacked Bitcoin or Ethereum.',
	'Immutable ledgers(completely accurate)',
	'Encrypted data stored on thousands of devices',
];
const privateData = [
	'Run faster and is cheaper to use than public blockchains',
	'Designed to comply fully with AML <span class="hidden-sm">(Anti-Money Laundering)</span> and HIPAA <span class="hidden-sm">(Health Insurance Portability &amp; Accountability Act)</span> laws.',
	'Governed by a few trusted nodes, protecting it from attack',
	'Gives full control to the owners',
];

const StyledWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	${media.laptopS`
		figure{
			max-width: 174px;
			.lock-img{
				width: 100%;
			}
		}
	`}
	${media.maxSm`
		flex-direction: column;
		align-items: center;
	`}
`;

const StyledFeaturesList = styled.div`
	width: 35%;
	padding-top: 40px;
	&:first-child {
		${H3} {
			left: 20%;
			span {
				flex-direction: row-reverse;
			}
		}
	}
	&:last-child {
		${H3} {
			right: 20%;
			flex-direction: row-reverse;
		}
	}
	${H3} {
		font-family: ${fonts.openSans};
		text-align: center;
		font-size: 36px;
		margin: 10px 0;
		display: flex;
		align-items: center;
		position: relative;
		span {
			flex-grow: 1;
			height: 1px;
			background: #e8e8e8;
			position: relative;
			display: flex;
			margin: 7px 0 0 0;
			&:before {
				width: 8px;
				height: 8px;
				background-color: #fd4326;
				box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
				border-radius: 50%;
				content: '';
				display: inline-block;
				margin: -4px 0 0 0;
			}
		}
	}
	.line {
		width: 81px;
		height: 2px;
		background-color: #fd4326;
		margin: 0 auto;
		display: block;
		margin-bottom: 50px;
	}
	.features-list {
		margin: 0;
	}
	.features-item {
		color: #7a8085;
		font-family: ${fonts.openSansLightItalic};
		font-size: 15px;
		line-height: 24px;
		margin-bottom: 30px;
	}
	${media.maxSm`
		width: auto;
		padding: 0;
		${H3}{
			font-size: 28px;
			left: 0!important;
			right: 0;
			display: block;
			text-align: center;
		}
		.features-list{
			list-style: none;
			padding: 0;
		}
		.features-item {
			background-color: #308fdf;
			box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
			width: 227px;
			height: 58px;
			color: #fff;
			padding: 10px;
			align-items: center;
			display: flex;
			justify-content: center;
			text-align: center;
			line-height: 16px;
			margin-left: auto;
			margin-right: auto;
		}
	`}
`;

const FeaturesList = ({ title, data }) => (
	<StyledFeaturesList>
		<H3>
			{title}
			<span className="hidden-sm" />
		</H3>
		<span className="line" />
		<ul className="features-list">
			{data.map((item, i) => (
				<li
					key={i}
					className="features-item"
					dangerouslySetInnerHTML={{ __html: item }}
				/>
			))}
		</ul>
	</StyledFeaturesList>
);
FeaturesList.propTypes = {
	title: PropTypes.string,
	data: PropTypes.array,
};

const SideBySide = () => (
	<StyledWrapper>
		<FeaturesList title="public blockchain" data={publicData} />
		<figure className="hidden-sm">
			<img src={lock} alt="lock" className="lock-img" />
		</figure>
		<FeaturesList title="private blockchain" data={privateData} />
	</StyledWrapper>
);

export default SideBySide;
