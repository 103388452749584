import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import TextContainer from '../../_common/TextContainer';
import P from '../../_common/P';
import SideBySide from './SideBySide';
import { media } from '../../../globalStyles';

const StyledTextContainer = styled(TextContainer)`
	margin-bottom: 100px;
	${media.maxSm`
		margin-bottom: 50px;
	`}
`;
const DescriptionText = () => (
	<StyledTextContainer>
		<P>
			Just as the name implies, a public blockchain is accessible by anyone. So,
			anyone from anywhere can use a public blockchain to input transactions and
			data as long as they are connected to the network. Public chains offer
			transaction data that can be verified in real-time by anybody. The more
			independent users or institutions that take part in verification, the more
			secure and decentralized the chain becomes.
		</P>
		<P>
			Private blockchains (permissioned blockchains) work similarly to public,
			but they also include access controls to restrict people or entities that
			can join the network. Unlike public blockchains, they operate like a
			centralized database system of today that limits access to certain users.
			They have one or more entities controlling the network, which leads to a
			reliance on third-parties to transact. <b>PixelTeh</b> is able to build
			customized public or private blockchains that offer powerful solutions for
			buying, selling, trading, and doing business.
		</P>
	</StyledTextContainer>
);
const Differences = () => (
	<ContentSection
		paddingBottom={0}
		paddingBottomSm={0}
		title="The Difference Between Public vs Private Blockchains"
		seoTitle={'h2'}
	>
		<DescriptionText />
		<SideBySide />
	</ContentSection>
);

export default Differences;
