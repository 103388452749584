import React from 'react';
import styled from 'styled-components';

import DevelopmentItem from './DevelopmentItem';

import icon1 from '../../../assets/img/blockchain-icon1.png';
import icon2 from '../../../assets/img/blockchain-icon2.png';
import icon3 from '../../../assets/img/blockchain-icon3.png';
import icon4 from '../../../assets/img/blockchain-icon4.png';
import { media } from '../../../globalStyles';

const StyledDevelopmentList = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 90px;
	${media.laptopS`
		flex-wrap: wrap;
	`}
`;
const items = [
	{
		title: 'Ico',
		icon: icon1,
		text:
			'Raise hundreds of thousands of dollars even millions in seconds with an ICO (Initial Coin Offering). This type of blockchain technology is a tool that revolutionizes currency – even the entire financial system.',
		alt: 'net image',
	},
	{
		title: 'smart contract',
		icon: icon2,
		text:
			'Why use a middleman? With a customized smart contract, you automate buyer-and-seller transactions securely. It makes your transactions traceable, transparent, and irreversible.',
		alt: 'paper contract',
	},
	{
		title: 'dapp',
		icon: icon3,
		text:
			'dApps (Decentralized Applications) connect users and developers directly without the need for anyone to host and manage the data or code. It’s a more efficient way to do business.',
		alt: 'two connected mobile phones',
	},
	{
		title: 'Wallets',
		icon: icon4,
		text:
			'Open up a bank account – virtually! Through wallet development, <b>PixelTeh</b> makes it possible to exchange as well as store cryptocurrency securely. A faster, cheaper, and better method of banking.',
		alt: 'wallet with money',
	},
];
const DevelopmentList = () => (
	<StyledDevelopmentList>
		{items.map(item => (
			<DevelopmentItem
				key={item.title}
				icon={item.icon}
				title={item.title}
				alt={item.alt}
			>
				{item.text}
			</DevelopmentItem>
		))}
	</StyledDevelopmentList>
);
export default DevelopmentList;
