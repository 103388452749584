import React from 'react';
import styled from 'styled-components';

import ContentSection from '../../ContentSection';
import H3 from '../../_common/H3';
import Particles from '../../Particles';
import { fonts, media } from '../../../globalStyles';
import TechSolutionsList from './TechSolutionsList';
import Container from '../../_common/Container';

const StyledTechSolutions = styled.div`
	position: relative;
	background-color: #365277;
	${H3} {
		font-size: 32px;
		color: #fff;
		font-family: ${fonts.robotoRegular};
		position: relative;
		z-index: 2;
		text-align: center;
	}
	.container {
		max-width: 1200px;
		margin: 0 auto;
		padding: 50px 15px 100px;
	}
	${media.maxSm`
		.container{
			padding-bottom: 70px;
		}
	`}
	${media.maxXs`
		${H3}{
			font-size: 24px;
		}
	`}
`;
const TechSolutions = () => (
	<ContentSection
		noHeader
		fluid
		disablePadding
		paddingBottom={0}
		paddingBottomSm={0}
	>
		<StyledTechSolutions>
			<Container>
				<H3>The Technological Solutions We Incorporate:</H3>
				<TechSolutionsList />
			</Container>
			<Particles />
		</StyledTechSolutions>
	</ContentSection>
);

export default TechSolutions;
