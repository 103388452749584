import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import H3 from '../../_common/H3';
import H4 from '../../_common/H4';
import P from '../../_common/P';

import { colors, fonts, media } from '../../../globalStyles';

const StyledDevelopmentItem = styled.div`
	width: 23%;
	border: 1px solid #23252c;
	text-align: center;
	padding: 40px 0;
	${H3} {
		color: ${colors.orange};
		font-family: ${fonts.loveloBlack};
		font-size: 30px;
		font-weight: 400;
	}
	${H4} {
		font-family: ${fonts.openSans};
		font-weight: 600;
		font-size: 22px;
		margin-bottom: 30px;
	}
	${H3}, ${H4} {
		text-transform: uppercase;
	}
	.item-icon {
		display: inline-block;
		margin-bottom: 20px;
	}
	${P} {
		padding: 0 30px;
		font-family: ${fonts.robotoLightItalic};
		color: ${colors.greyItalicTitle};
		font-weight: 300;
		line-height: 28px;
		b {
			font-family: ${fonts.robotoMedium};
			font-style: italic;
		}
	}
	${media.laptopS`
		width: calc(50% - 30px);
		margin: 15px;
	`}
	${media.maxSm`
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		.item-icon, ${P} {
			display: none;
		}
		${H4} {
			margin: 0;
		}
	`}
`;

const DevelopmentItem = ({ title, icon, children, alt }) => (
	<StyledDevelopmentItem>
		<H3>{title}</H3>
		<H4>development</H4>
		<img src={icon} alt={alt} className="item-icon" />
		<P dangerouslySetInnerHTML={{ __html: children }} />
	</StyledDevelopmentItem>
);

DevelopmentItem.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	children: PropTypes.string,
	alt: PropTypes.string,
};

export default DevelopmentItem;
