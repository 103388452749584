import React from 'react';
import styled from 'styled-components';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import ImageDescription from './ImageDescription';
import DevelopmentList from './DevelopmentList';
import CenterAnimatedButton from '../_common/CenterAnimatedButton';

const StyledP = styled(P)`
	&&& {
		margin-bottom: 110px;
	}
`;
const DescriptionBlock = () => (
	<PageDescription
		title="Blockchain Development for Greater Impact"
		subtitle="A secure, self-governed way to buy, sell, and trade"
		bgColor="white"
	>
		<StyledP>
			Step into the future of banking, trading, buying, selling, and doing
			business. <b>PixelTeh</b> specializes in providing high-end solutions for
			cryptocurrency and blockchain development. Our developers have years of
			experience in designing, building, and deploying security applications.
			Due to our in-depth understanding of blockchain technology and our
			comprehensive knowledge of the newest innovations in the cryptocurrency
			world, we are able to deliver top-notch and custom tailored blockchain
			software development services.
		</StyledP>
		<ImageDescription />
		<DevelopmentList />
		<CenterAnimatedButton
			linkTo="/contact#contact-section"
			gaLabel="technologies-blockchain Looking to launch in ico"
		>
			Looking to launch in ico?
		</CenterAnimatedButton>
	</PageDescription>
);

export default DescriptionBlock;
